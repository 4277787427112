<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="license"
      name="license"
      label="车牌号"
      maxlength="7"
    />
    <van-field
      v-model="alias"
      name="alias"
      label="大号"
    />
    <van-field
      v-model="quantity"
      name="quantity"
      label="升"
      required
      :rules="[{ required: true, message: '请填写油量' }]"
    />
    <van-field
      v-model="dateTime"
      readonly
      clickable
      name="dateTime"
      label="时间选择"
      placeholder="点击选择时间"
      required
      @click="showPicker = true"
    />
      <van-popup v-model:show="showPicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datehour"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
      />
    </van-popup>

    <div style="margin: 16px;">
      <van-button :loading="$store.state.loading" round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import moment from "moment";

export default {
  props: ['data'],
  data() {
    return {
      license: '',
      quantity: '',
      alias: '',
      dateTime: '',

      showPicker: false,
      minDate: new Date(2000, 1, 1),
      maxDate: new Date(),
      currentDate: new Date(),
    };
  },
  mounted() {
    const { $watch, $emit } = this;

    $watch('data', (newValue) => {
      const { dateTime, quantity, license, alias } = newValue;

      this.dateTime = dateTime;
      this.quantity = quantity;
      this.license = license;
      this.alias = alias;
    })

    $watch('license', (newValue) => {
      if (newValue && newValue.length == 7) {
        $emit('vehicle', { plateNo: newValue });
      }
    })

    $watch('alias', (newValue) => {
      if (newValue) {
        $emit('vehicle', { alias: newValue });
      }
    })
  },
  methods: {
    onConfirm(time) {
      this.dateTime = moment(time).format('YYYY-MM-DD HH:mm:ss');
      this.showPicker = false;
    },
    onSubmit(values) {
      this.$emit('onsubmit', values);
    },
  },
};
</script>

<template>
  <div class="oil-edit">
    <UpdateForm
      :data="data"
      v-on:vehicle="handleVehicle"
      v-on:onsubmit="onSubmit"
    />
  </div>
</template>

<script>
import moment from "moment";
import { Notify } from 'vant';
import UpdateForm from "./components/UpdateForm.vue";

export default {
  components: { UpdateForm },
  data() {
    return {
      data: {},
      showPicker: false,
      minDate: new Date(2000, 1, 1),
      maxDate: new Date(),
      currentDate: new Date(),
    };
  },
  mounted() {
    const { $route } = this;
    this.loadView({ id: $route.query.id });
  },
  methods: {
    loadView(params) {
      this.$request.queryOilTicketView({ ...params }, response => {
        if (response.status === 200) {
          const result = response.data;
          const { license, alias, quantity, dateTime } = result;

          this.license = license;
          this.alias = alias;
          this.quantity = quantity;
          this.dateTime = dateTime;
          this.data = result;
        }
      })
    },
    handleVehicle({ plateNo, alias }) {
      this.loadVehicle({ plateNo, alias });
    },
    loadVehicle(params) {
      this.$request.queryVehicle({ ...params }, response => {
        if (response.status === 200) {
          const result = response.data;
          if (result) {
            if (result.driver) {
              result.driverName = result.driver.driverName;
              result.driverId = result.driver.id;
              result.driverUserId = result.driver.userId;
            }
            this.data = { ...this.data, ...result };
          } else {
            let data = JSON.parse(JSON.stringify(this.data));
            data.license = params.plateNo;
            data.alias = params.alias;
            this.data = data;
          }
        }
      })
    },
    onConfirm(time) {
      this.dateTime = moment(time).format('YYYY-MM-DD HH:mm:ss');
      this.showPicker = false;
    },
    onSubmit(values) {
      const { $store, $route, data: { img } } = this;
      values.img = img;
      values.id = $route.query.id*1;

      if ($store.state.loading) return;
      $store.commit('setLoading', { loading: true });

      this.$request.putOilTicket({ ...values }, response => {
        if (response.status === 200) {
          const result = response.data;
          $store.commit('setLoading', { loading: false });

          if (result.code === 1) {
            Notify({ type: 'success', message: '保存成功' });
            this.$router.replace({ path: '/complate' });
          }else{
            Notify({ type: 'warning', message: result.msg });
          }
        }
      })
    },
  },
};
</script>
